// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import { AuthProvider } from './Components/AuthContext';
import { LanguageProvider } from './Components/LanguageContext';
import NotFound from './Components/NotFound';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} /> {/* Updated for React Router v6 */}
            </Routes>
          </div>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;