import React, { useContext } from "react";
import './OurStory.css';
import { LanguageContext } from './LanguageContext';

const OurStory = () => {
    const { translations } = useContext(LanguageContext);
    const t = translations;

    return (
        <div className="story-container">
            <div className="story-content">
                <section className="story-section">
                    <div className="text-block">
                        <h2>{t.theIdeaTitle}</h2>
                        <p>{t.theIdeaDescription}</p>
                    </div>
                    <div className="image-block">
                        <img 
                            src="./thinkingman.png" 
                            alt={t.theIdeaImageAlt}
                        />
                    </div>
                </section>

                <section className="story-section reverse">
                    <div className="text-block">
                        <h2>{t.thePromiseTitle}</h2>
                        <p>{t.thePromiseDescription}</p>
                    </div>
                    <div className="image-block">
                        <img 
                            src="./bookdraw.png" 
                            alt={t.thePromiseImageAlt}
                        />
                    </div>
                </section>
            </div>
        </div>
    );
};

export default OurStory;