import React, { useContext, useState } from "react";
import "./FAQ.css";
import { LanguageContext } from "./LanguageContext";

const FAQ = () => {
    const { translations } = useContext(LanguageContext);
    const t = translations; // Translation object based on the current language

    const faqData = [
        { question: t.faq.question1, answer: t.faq.answer1 },
        { question: t.faq.question2, answer: t.faq.answer2 },
        { question: t.faq.question3, answer: t.faq.answer3 },
        { question: t.faq.question4, answer: t.faq.answer4 },
        { question: t.faq.question5, answer: t.faq.answer5 },
        { question: t.faq.question6, answer: t.faq.answer6 },
        { question: t.faq.question7, answer: t.faq.answer7 },
        { question: t.faq.question8, answer: t.faq.answer8 },
        { question: t.faq.question9, answer: t.faq.answer9 },
        { question: t.faq.question10, answer: t.faq.answer10 },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h1 className="faq-title">{t.faqTitle}</h1>
            <div className="faq-list">
                {faqData.map((item, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? "active" : ""}`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">
                            {item.question}
                            <span className="faq-icon">
                                {activeIndex === index ? "−" : "+"}
                            </span>
                        </div>
                        <div className="faq-answer">
                            <p>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;