// LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import translationsData from '../translations.json'; // Renaming imported JSON to avoid naming conflict

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || (navigator.language.startsWith('fr') ? 'fr' : 'en'));
  const [currentTranslations, setCurrentTranslations] = useState(translationsData[language]); // Renamed state variable

  useEffect(() => {
    setCurrentTranslations(translationsData[language]);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ language, translations: currentTranslations, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
