import React, { useState, useContext } from "react";
import axios from "axios"; // Ensure axios is imported
import validator from "validator"; // Import validator for email validation
import { LanguageContext } from './LanguageContext';

const EmailSignUp = () => {
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAlready, setShowAlready] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  
  const { translations } = useContext(LanguageContext);
  const t = translations;

  // Define the base URL (replace with your environment variables if necessary)
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT === "PROD"
      ? process.env.REACT_APP_PROD_URL
      : process.env.REACT_APP_DEV_URL;

  const handleEmailSubmit = async () => {
    if (!email.trim() || !validator.isEmail(email)) {
      setShowEmail(true);
      setFadeOut(false);

      // Timeout for invalid email message fade-out
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => setShowEmail(false), 1000);
      }, 2000); // Display time: 2 seconds
      return;
    }

    try {
      await axios.post(`${baseURL}/submitEmail`, { email });
      setShowAlready(false);
      setShowEmail(false);
      setShowMessage(true);
      setFadeOut(false);
      setEmail("");

      // Timeout for success message fade-out
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => setShowMessage(false), 1000);
      }, 3000); // Display time: 3 seconds
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle the 409 Conflict (duplicate email)
        setShowMessage(false);
        setShowEmail(false);
        setShowAlready(true);

        setFadeOut(false);
        setEmail("");

        // Timeout for duplicate email message fade-out
        setTimeout(() => {
          setFadeOut(true);
          setTimeout(() => setShowAlready(false), 1000);
        }, 3000); // Display time: 3 seconds
      } else {
        console.error("Error submitting email:", error);
      }
    }
  };

  return (
    <div className="email-signup">
      <input
        type="email"
        placeholder={t.enterYourEmail}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEmailSubmit();
          }
        }}
        className="input-field-email"
        required
      />
      <button onClick={handleEmailSubmit}>{t.joinWaitlist}</button>

      {/* Message Wrapper */}
      <div className="message-container">
        {showMessage && (
          <p className={`thank-you-message ${fadeOut ? "fade-out" : "fade-in"}`}>
            {t.thankYou}
          </p>
        )}
        {showEmail && (
          <p className={`invalid-email ${fadeOut ? "fade-out" : "fade-in"}`}>
            {t.invalidEmail}
          </p>
        )}
        {showAlready && (
          <p className={`already-joined ${fadeOut ? "fade-out" : "fade-in"}`}>
            {t.alreadyjoined}
          </p>
        )}
      </div>
    </div>
  );
};

export default EmailSignUp;