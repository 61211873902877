import React, { useContext, useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import heic2any from "heic2any";
import "./SimpleImageUploader.css";
import { MdOutlinePermMedia } from "react-icons/md";
import { FaImage, FaRegImage } from "react-icons/fa";
import { LanguageContext } from './LanguageContext';
import { Divider } from "@fluentui/react-divider";

// Utility function to detect mobile devices
const isMobileDevice = () => /Mobi|Android|iPhone/i.test(navigator.userAgent);

const SimpleImageUploader = () => {
    const { language, translations } = useContext(LanguageContext);
    const t = translations;

    const baseURL = process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_PROD_URL
        : process.env.REACT_APP_DEV_URL;

    const [uploadedImage, setUploadedImage] = useState(null);
    const [story, setStory] = useState("");
    const [loading, setLoading] = useState(false);
    const [attemptsExceeded, setAttemptsExceeded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const storyRef = useRef(null);

    const onDrop = useCallback(async (acceptedFiles) => {
        console.log("onDrop triggered");

        // Reset states before processing
        setErrorMessage("");
        setAttemptsExceeded(false);

        let file = acceptedFiles[0];

        if (!file) {
            console.error("No file selected.");
            alert("No file selected.");
            return;
        }

        console.log("Accepted file:", file);

        // Check file size (e.g., 5MB limit)
        if (file.size > 5 * 1024 * 1024) {
            console.error(`File size exceeds the limit: ${file.size} bytes.`);
            alert("File size exceeds the limit of 5MB.");
            return;
        }

        // Check and convert HEIC to JPEG
        if (file.type === "image/heic" || file.type === "image/heif") {
            console.log("Converting HEIC/HEIF file to JPEG...");
            try {
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                file = new File([convertedBlob], file.name.replace(/\.\w+$/, ".jpeg"), { type: "image/jpeg" });
                console.log("File converted successfully:", file);
            } catch (error) {
                console.error("Error converting HEIC to JPEG:", error.message, error.stack);
                alert("Failed to convert HEIC image. Please use JPEG or PNG.");
                return;
            }
        }

        // MIME type check
        if (!["image/jpeg", "image/jpg", "image/png", "image/heic", "image/heif"].includes(file.type)) {
            console.error(`Unsupported file type: ${file.type}`);
            alert("Only JPEG and PNG files are supported.");
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append("images", file);
        formData.append("language", language);

        console.log("Uploading file to server...");
        try {
            const response = await axios.post(`${baseURL}/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (!response.data || !response.data.storyWithImages) {
                alert("Failed to retrieve story data.");
                console.error("No story data received from the server");
            }

            console.log("Server response:", response);

            if (response.data && response.data.storyWithImages) {
                const { storyWithImages } = response.data;
                console.log("Received story with images:", storyWithImages);
                setUploadedImage(`data:image/jpeg;base64,${storyWithImages[0].image}`);
                setStory(storyWithImages[0].text);

                // Smoothly scroll to the story preview section
                storyRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
            } else {
                console.error("No story data received from the server");
                alert("Failed to retrieve story data.");
            }
        } catch (error) {
            if (error.response?.status === 403 && error.response?.data?.attemptsExceeded) {
                // Handle attempts exceeded error
                setAttemptsExceeded(true);
                // No need to set errorMessage since we're not using it
            } else {
                setErrorMessage("Something went wrong. Please try again later.");
            }
        } finally {
            setLoading(false);
            console.log("Image upload process completed.");
        }
    }, [language, baseURL]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png, image/heic, image/heif",
        maxFiles: 1,
        noDrag: isMobileDevice(),
        disabled: loading || attemptsExceeded,
    });

    return (
        <div className="simple-image-uploader">
            <div className="uploader-container">
                <div
                    {...getRootProps()}
                    className={`dropzone ${loading ? "loading" : ""} ${isDragActive ? (isDragAccept ? "accept" : "reject") : ""}`}
                >
                    {loading && (
                        <div className="particle-container">
                            {[...Array(20)].map((_, i) => (
                                <div
                                    key={i}
                                    className="particle"
                                    style={{
                                        '--x': Math.random(),
                                        '--y': Math.random(),
                                        top: `${Math.random() * 100}%`,
                                        left: `${Math.random() * 100}%`,
                                    }}
                                ></div>
                            ))}
                        </div>
                    )}

                    <input {...getInputProps()} accept="image/*" />

                    {loading ? (
                        <div className="text-uploader">
                            <div>{t.loading}</div>
                        </div>
                    ) : (
                        <p>{isMobileDevice() ? t.click_to_upload : t.drag_here}</p>
                    )}
                    <MdOutlinePermMedia className="media-icon" />

                    <div className={`hover-icons ${loading ? "loading" : ""}`}>
                        <FaImage className="hover-icon image-icon" />
                        <FaRegImage className="hover-icon reg-image-icon" />
                    </div>
                </div>
                {attemptsExceeded && (
                    <div className="error-message">
                        {t.maxAttemptsReached}
                    </div>
                )}
                {uploadedImage && (
                    <div className="story-preview" ref={storyRef}>
                        <div className="intro-story">{t.storyimagined}</div>
                        <div className="intro-story">{t.assoon}</div>
                        <div className="story-content">
                            <img
                                src={uploadedImage}
                                alt="Uploaded preview"
                                className="uploaded-image"
                                onError={(e) => {
                                    console.error("Failed to load image:", e);
                                    alert("Could not display the uploaded image. Please try again.");
                                }}
                            />
                            <p className="story-text">"{story}"</p>
                        </div>
                        <div>
                            <Divider className="divider-cta" />
                            <img src="/bulb.png" alt="CTA icon" className="cta-img" />
                            <p className="cta-sub">{t.ctasub}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SimpleImageUploader;