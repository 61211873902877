import React, { useContext } from 'react';
import './WaitlistBanner.css'; // Import the CSS file

import { LanguageContext } from './LanguageContext';

const WaitlistBanner = () => {
    const { translations } = useContext(LanguageContext);
    const t = translations;
    return (
        <div
    className="backgroundBanner"
    style={{ backgroundImage: `url('/background-doodles.jpg')` }}
>
    <div className="banner">
        <div className="content">
            <h1 className="title">{t.whyJoin}</h1>
            <p className="description">{t.joiningNow}</p>
        </div>
    </div>
</div>
    );
};

export default WaitlistBanner;