// Header.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@fluentui/react-components';
import './Header.css';
import { LanguageContext } from './LanguageContext';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Header = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { translations, changeLanguage } = useContext(LanguageContext);
  const t = translations;

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    };
    window.addEventListener('keydown', handleEscapeKey);
    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [showModal]);

  const toggleModal = () => setShowModal(!showModal);

  // Close modal and change language
  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setShowModal(false);
  };

  return (
    <>
      <div className="header-container">
        <div className="header" onClick={() => navigate('/')}>
          <img src="/Book.png" alt="kid drawing" className="bookicon" />
          <h2 className="header-title">DrawnMagic</h2>
        </div>
        <div>
          <div className="changelanguage" onClick={toggleModal}>
            <p>🗺️ {t.changelanguage}</p>
          </div>
        </div>
      </div>
      <div className={`divider-header ${scrolled ? 'visible' : ''}`}>
        <Divider />
      </div>

      {showModal && (
        <div className="language-modal">
          <div className="modal-content">
            <h3>{t.selectLanguage}</h3>
            <button className="language-button" onClick={() => handleLanguageChange('en')}>
              <span className="fi fi-gb"></span> English
            </button>
            <button className="language-button" onClick={() => handleLanguageChange('fr')}>
              <span className="fi fi-fr"></span> Français
            </button>
            <button onClick={toggleModal} className="close-modal">Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
